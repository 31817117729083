<template>
  <v-card rounded="0">
    <v-container>
      <v-row>
        <v-col cols="12" lg="6">
          <v-img src="@/assets/images/2020_03-31-Sudbury-worship.jpg" />
        </v-col>
        <v-col cols="12" lg="6">
          <v-img src="@/assets/images/2020_03-31-Sudbury-prayer.jpg" />
        </v-col>
      </v-row>
      <h3 class="h3 text-center mt-2">Join us and worship and pray with us in Zoom Meeting</h3>
      <v-divider />
      <h4 class="h4 mt-4">Every Sabbath morning Worship at 10am</h4>
      <h4 class="h4">Every Wednesday evening at 7pm for mid-week Prayer meeting</h4>
      <h5 class="h5 my-4"><a class="text-decoration-none" style="color:green" href="https://zoom.us/j/97289172931?pwd=cHQ4ZUJrM2EzQS9wMWZQSXMvWHhBUT09" target="_blank">https://zoom.us/j/97289172931?pwd=cHQ4ZUJrM2EzQS9wMWZQSXMvWHhBUT09</a></h5>
      <h5 class="h5">MEETING ID: 914 435 4189 <br> Password: WORSHIP</h5>
      <h5 class="h5 my-4">One tap mobile <br> +19292056099,,97289172931#,,,,0#,,171330# US (New York) <br> +12532158782,,97289172931#,,,,0#,,171330# US (Tacoma)</h5>
      <h5 class="h5 my-4">Dial by your location <br>
        +1 929 205 6099 US (New York) <br>
        +1 253 215 8782 US (Tacoma) <br>
        +1 301 715 8592 US (Germantown) <br>
        +1 312 626 6799 US (Chicago) <br>
        +1 346 248 7799 US (Houston) <br>
        +1 669 900 6833 US (San Jose)</h5>
      <h5 class="h5">Meeting ID: 914 435 4189 <br> Password: WORSHIP</h5>
      <h5 class="h5 my-4">Find your local number: <a class="text-decoration-none" style="color:green" href="https://zoom.us/u/aezuSjPwv" target="_blank">https://zoom.us/u/aezuSjPwv</a></h5>
      <h3 class="h3 my-4">Join us for our "Stand!" series from 7:00pm - 9:00pm</h3>
    </v-container>
  </v-card>
</template>
<script>

export default {
  head: {
    title: function () {
      return {
        inner: 'Bulletins'
      }
    }
  }
}
</script>
